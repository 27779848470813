import { useState } from 'react';
import { getLanguage, getAllLanguages, getUrlParamsObject } from './helpers';
import { isEqual } from 'lodash';

// We need to map ISO-631 to ISO-3166
// Obviously something like "EN" in ISO-631 does not have a corresponding tag in ISO-3166 (EN is not a country)
// Most of them are okay to convert directly but these are the exceptions
// (Since we don't have things like en-US, en-GB etc we just assume GB)
const relation = {
  am: 'et',
  ar: 'sy',
  az: 'az',
  bn: 'bd',
  be: 'by',
  ca: 'es',
  cs: 'cz',
  el: 'gr',
  en: 'gb',
  et: 'ee',
  fa: 'ir',
  bg: 'bg',
  ha: 'ne',
  hi: 'in',
  hy: 'am',
  id: 'id',
  ja: 'jp',
  jv: 'id',
  km: 'kh',
  ko: 'kr',
  lv: 'lv',
  mr: 'in',
  sw: 'ke',
  ta: 'lk',
  te: 'in',
  uk: 'ua',
  vi: 'vn',
  zh: 'cn',
  sv: 'se',
  sr: 'rs',
};

const getLocalizedLanguagesList = (config) => {
  const languages = getAllLanguages(config);
  return languages.map((lang) => {
    const languageIntl = new global.Intl.DisplayNames([lang], {
      type: 'language',
    });
    const countryCode = (relation[lang] ?? lang).toUpperCase();
    return { label: lang, value: languageIntl.of(lang), countryCode };
  });
};

export const useLanguage = () => {
  const query = getUrlParamsObject(
    new URLSearchParams(location.search).entries(),
  )
  const [lang, setLang] = useState();
  const [localizedLanguages, setLocalizedLanguages] = useState([]);

  const loadLangsFromConfig = (config) => {
    const loadedLang = getLanguage(query, config);
    if(!lang) {
      setLang(loadedLang);
    }
    const localizedLangsList = getLocalizedLanguagesList(config);
    if (!isEqual(localizedLangsList, localizedLanguages)) {
      setLocalizedLanguages(localizedLangsList);
    }
    return lang;
  };

  return {
    lang,
    setLang,
    loadLangsFromConfig,
    localizedLanguages,
  };
};
